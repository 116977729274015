import { useEffect, useState, useCallback, useContext } from "react";
import Event from "./pages/event/Event";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Auth from "./Auth/Auth";
import Callback from "./Callback";
import "mapbox-gl/dist/mapbox-gl.css";
import "./App.scss";
import authLocalDataService from "./services/data/authLocalDataService";
import { Context } from "./Context/LanguageContext";
import queryString from "query-string";
import { ThemeProvider } from "./Context/ThemeContext";
import useSessionTimeout from "./Hooks/useSessionTimeout";

const App = (props) => {
  const auth = new Auth(props.history, props.location.pathname);
  const contextWrapper = useContext(Context);
  const [access_token, setAccessToken] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const hasSessionTimedOut = useSessionTimeout(
    authLocalDataService.getExpiresAt()
  );

  useEffect(() => {
    const axios = require("axios");
    const qs = require("qs");

    const url = process.env.REACT_APP_API_AUTH_URL;

    const data = {
      grant_type: "client_credentials",
      scope: "openid",
    };

    const auth = {
      username: process.env.REACT_APP_API_CLIENT_ID,
      password: process.env.REACT_APP_API_SECRET,
    };

    const options = {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(data),
      auth: auth,
      url,
    };

    axios(options)
      .then((response) => {
        const accessToken = response.data.access_token;
        const expiresAt =
          parseInt(response.data.expires_in) * 1000 + new Date().getTime();
        const prevIdToken = authLocalDataService.getIdToken();
        authLocalDataService.setAuth({
          accessToken,
          idToken: prevIdToken,
          expiresAt: expiresAt,
        });
        setAccessToken(accessToken);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Handles display:none and other render preventing measures when embedded. Prevents mounting until component has a height and width
  const refCallback = useCallback((ref) => {
    if (!ref || !ResizeObserver) {
      return;
    }
    //Resize observer needed because changes can be done outside of the iframe and react
    const resizeObserver = new ResizeObserver((mutations) => {
      if (
        mutations?.[0]?.contentRect?.height !== 0 &&
        mutations?.[0]?.contentRect?.width !== 0
      ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });

    const heldRef = ref;
    resizeObserver.observe(heldRef);
    return () => {
      resizeObserver.unobserve(heldRef);
    };
  }, []);

  return (
    <>
      {hasSessionTimedOut && (
        <div className="overlay">
          Your session has expired. Please refresh the page or&nbsp;
          <span
            onClick={() => {
              window.location.reload();
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            click here
          </span>
          .
        </div>
      )}
      <div className={hasSessionTimedOut ? "blurred" : ""}>
        <Router>
          <main ref={refCallback}>
            <section>
              <Route
                exact
                path="/callback"
                render={(props) => (
                  <Callback
                    auth={auth}
                    {...props}
                  />
                )}
              />

              {access_token && isVisible && (
                <Route
                  path="/"
                  render={(props) => (
                    <ThemeProvider>
                      <Event
                        auth={auth}
                        contextWrapper={contextWrapper}
                        source={"event"}
                        apiToken={`Bearer ${access_token}`}
                        queryString={queryString.parse(props.location.search)}
                        {...props}
                      />
                    </ThemeProvider>
                  )}
                />
              )}
            </section>
          </main>
        </Router>
      </div>
    </>
  );
};

export default App;
